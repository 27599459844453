import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link as GLink } from 'gatsby'
import {
  Container,
  Box,
  Flex,
  css,
  MenuButton,
  MenuList,
  MenuItem
} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import ServicesDetails from '@solid-ui-blocks/Faq/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import CompaniesPhoto from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import JoinCompanies from '@solid-ui-blocks/Content/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

import './customStyleC.css'

import $ from 'jquery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import { FaQuoteRight } from 'react-icons/fa'
import { FaStar } from 'react-icons/fa'
import { FaRegStar } from 'react-icons/fa'
import { FaGooglePlay } from 'react-icons/fa'
import { FaAppStore } from 'react-icons/fa'

import shutterstock_1 from './assets/shutterstock_1.jpg'
import shutterstock_2 from './assets/shutterstock_2.jpg'
import shutterstock_3 from './assets/shutterstock_3.jpg'

import appStoreBadge from './assets/appStoreBadge.png'
import playStoreBadge from './assets/playStoreBadge.png'

import Testimonials from '../Testimonials'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block07'

import Tabs from '@solid-ui-components/Tabs'
const ServiceCPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const settings = {
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    speed: 2000
  }

  const MyHTMLComponent = () => {
    return (
      <div className='service_spacer'>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sec1'>
              <h1>Floki Supermarket App (B2C)</h1>
              <p>
                Elevate your online presence and increase sales with a
                cuting-edge grocery delivery app.
              </p>
            </div>
          </div>
        </div>
        <div class='discrptn'>
          <div class='mycontainer'>
            <p>
              Introducing Floki cutting-edge White label supermarket app,
              designed exclusively for supermarkets and grocery stores. With
              Floki’s user-friendly app for grocery delivery, customers can
              easily shop for groceries online, create shopping lists, and enjoy
              doorstep delivery. Our customizable supermarket delivery app is
              fast, reliable, and tailored to meet your business needs, enabling
              you to reach more customers and boost sales. Experience the
              convenience of modern s hopping with our user-friendly online
              grocery ordering system. Simplify your supermarket store
              management today!
            </p>
          </div>
        </div>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_1} alt='product_img' />
                  {/* <img src="images/shutterstock_1.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Key Features :</h4>
                  <ul class='liststyle'>
                    <li>
                      Floki’s <b>user-friendly</b> supermarket delivery app
                      ensures seamless navigation and personalized experiences.
                      Find products quickly with our{' '}
                      <b>smart search algorithm</b>.
                    </li>
                    <li>
                      Engage users, reduce churn rate, and drive growth with
                      <b> advanced marketing tools</b> integrated into Floki’s
                      app for grocery delivery.
                    </li>
                    <li>
                      Keep your customers updated with{' '}
                      <b>order tracking and delivery notifications</b>, while
                      our app integrates with major{' '}
                      <b>payment gateways and delivery partners</b> for
                      convenience.
                    </li>
                    <li>
                      Expand your global reach with
                      <b>multi-language and currency support</b>, making Floki
                      white label supermarket grocery delivery app an ideal
                      solutions for mobile grocery app development.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <h4>Benefits :</h4>
                  <ul class='liststyle'>
                    <li>
                      Expand reach, boost revenue, and tap into a wider customer
                      base with Floki white label supermarket app.
                    </li>
                    <li>
                      Personalize experiences, recommend related products, and
                      offer promotions. Provide customers convenience and a
                      hassle-free shopping experience with our mobile app for
                      grocery delivery.
                    </li>
                    <li>
                      Reduce costs, optimize store operations, and enhance
                      customer experience by integrating your supermarket
                      delivery app with your POS and delivery partners
                      platforms.
                    </li>
                    <li>
                      Reduce the costs of running a physical store by
                      streamlining your operations with our mobile app.
                    </li>
                    <li>
                      Customize your online store with our White label
                      supermarket app. Align content to your brand, enhance
                      customer experience with relevant articles, images, and
                      videos.
                    </li>
                  </ul>
                </div>
                <div class='colm6'>
                  <img src={shutterstock_2} alt='product_img' />
                  {/* <img src="images/shutterstock_2.jpg" alt="product_img"> */}
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_3} alt='product_img' />
                  {/* <img src="images/shutterstock_3.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Use Cases :</h4>
                  <ul class='liststyle'>
                    <li>
                      A supermarket owner who wants to expand their reach and
                      attract more customers.
                    </li>
                    <li>
                      A grocery store manager who wants to streamline their
                      operations and reduce their costs.
                    </li>
                    <li>
                      A business owner who wants to offer a competitive
                      advantage over other supermarkets or grocery stores that
                      don't have an online presence.
                    </li>
                    <li>
                      An e-commerce manager who developed an online solution
                      that is not meeting his expectations and is looking for a
                      better technology like app for grocery delivery that
                      actually works.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Helmet>
        <title>
          App for Grocery Delivery | White Label Supermarket App | Floki
          Solutions
        </title>
        <meta
          name='google-site-verification'
          content='jvulVjeMbr8Qb07aPAbFxWgBuwOl_Q2A9EvJoemO1co'
        />
        <meta
          name='description'
          content='Need a white label supermarket app for your grocery delivery business? We specialize in online grocery development and can customize an app for your needs.'
        />
        <meta
          name='keywords'
          content='app for grocery delivery, supermarket delivery app, online grocery app development, grocery mobile app development, White label supermarket app'
        />

        <meta
          property='og:title'
          content='App for Grocery Delivery | White Label Supermarket App | Floki Solutions'
        />
        <meta
          property='og:description'
          content='Need a white label supermarket app for your grocery delivery business? We specialize in online grocery development and can customize an app for your needs.'
        />

        <meta
          property='og:url'
          content='https://flokisolutionsmain.gatsbyjs.io/floki-supermarket-app/'
        />
        <meta property='og:type' content='website' />
      </Helmet>

      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}

      <Header content={content['header']} />

      <MyHTMLComponent></MyHTMLComponent>
      <Tabs space={4} variant='pill'>
        <FeatureTabOne content={content['feature-tab-one']} />
        <FeatureTabTwo content={content['feature-tab-two']} reverse />
        <FeatureTabThree content={content['feature-tab-three']} />
      </Tabs>

      <Testimonials />

      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query ServiceCPageContent {
    allBlockContent(
      filter: { page: { in: ["site/floki-supermarket-app", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServiceCPage
